.home-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  background: linear-gradient(to right, #7ca1e2, #2575fc);
  color: rgb(51, 49, 49);
  text-align: center;
}

.home-content h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.home-content p {
  font-size: 1.25rem;
  margin-bottom: 30px;
}

.chatbot-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #fff;
  color: #2575fc;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s;
}

.chatbot-button:hover {
  background-color: #f0f0f0;
}
