/* RESET some default browser styles (optional) */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Body styling */
body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #f4f4f4;
  /* Soft gray background for the entire page */
}

/* 
  CHATBOT CONTAINER 
  Full width, full height for a modern, immersive experience
*/
.chatbot-container {
  width: 100%;
  height: 100vh;
  /* Occupy full viewport height */
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  /* White background for the chatbot itself */
}

/* 
  HEADER 
  Make it look more modern with a gradient or a solid color
*/
.header {
  display: flex;
  align-items: center;
  justify-content: center;  /* Center title horizontally */
  height: 55px;
  padding: 0 20px;
  background: linear-gradient(45deg, #009ffd, #2a2a72);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header h1 {
  font-size: 1.1rem;
  font-weight: 600;
  color: #fff;
  margin: 0;
}

/* 
  CHAT WINDOW 
  This area scrolls to accommodate messages
*/
.chat-window {
  flex: 1;
    overflow-x: visible; 
  /* Fill remaining vertical space */
  overflow-y: auto;
  /* Make chat window scrollable */
  padding: 20px;
  background-color: #fafafa;
  position: relative;
  /* For absolutely positioned loading spinner, if needed */
}

/*
  MESSAGES:
  - user-message on LEFT
  - agent-message on RIGHT
*/

/* Common message container styling */
.chat-message {
  max-width: 80%;
  margin-bottom: 10px;
  word-wrap: break-word;
  display: flex;
  /* We'll control alignment with .user-message vs .agent-message */
}

/* USER MESSAGE ON LEFT */
.user-message {
  align-self: flex-start;
  /* Forces alignment to the left */
  background-color: #e8f0fe;
  /* Lightish background for user bubble */
  color: #333;
  border-radius: 15px 15px 15px 0;
  /* Bubble shape with arrow on left side */
  padding: 10px 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* AGENT MESSAGE ON RIGHT */
.agent-message {
  align-self: flex-end;
  /* Forces alignment to the right */
  background-color: #dceff1;
  /* Lightish teal for agent bubble */
  color: #333;
  border-radius: 15px 15px 0 15px;
  /* Bubble shape with arrow on right side */
  padding: 10px 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-left: auto;
  /* Push it to the right */
}

/*
  LOADING ICON 
  (Using MUI's CircularProgress, so position a container absolutely if needed)
*/
.loading-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Product card for courses */
.product-card {
  flex: 1 1 calc(33.333% - 40px);
  /* 3 cards per row on desktop */
  background: linear-gradient(145deg, #ffffff, #c6d4ec);
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  padding: 25px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  min-width: 280px;
  /* So it doesn’t shrink too much */
}

/* Hover effect */
.product-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
}

.expanded-table {
  margin-top: 10px;
  display: block;
  width: 100%;                /* Container spans full width */
  overflow-x: auto;           /* Scroll horizontally if table is too wide */
  -webkit-overflow-scrolling: touch; /* Smooth iOS scroll */
  /* optional: text-align: center; 
     but margin: 0 auto on the table is usually enough to center if narrower than container
  */
}

.expanded-table table {
  margin: 0 auto;             /* Center the table horizontally if it's narrower than the container */
  width: max-content;         /* Let table expand to fit its content */
  min-width: 600px;           /* Or 800px if you have many columns */
  border-collapse: collapse;
  margin-top: 10px;           /* Or remove this if you don't need extra spacing */
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.expanded-table th,
.expanded-table td {
  border: 1px solid #e0e0e0;
  padding: 10px;
  text-align: center;
  font-size: 14px;
}

.expanded-table th {
  background-color: #7ca1e2;
  color: #fff;
  font-weight: bold;
}

.expanded-table tr:hover {
  background-color: #f7f4f4;
}

/* Card option buttons (Select, Show More) */
.card-options {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.card-options button {
  padding: 8px 15px;
  border: none;
  background: linear-gradient(145deg, #7bb2ec, #599de7);
  color: white;
  cursor: pointer;
  border-radius: 25px;
  font-weight: 600;
  transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease;
  box-shadow: 0 4px 10px rgba(0, 123, 255, 0.2);
  font-size: 0.9rem;
  margin: 0 5px;
}

.card-options button:hover {
  background-color: #7ca1e2;
  transform: translateY(-2px);
  box-shadow: 0 8px 20px rgba(0, 86, 179, 0.3);
}

/* Chat input area (sticky at bottom) */
.chat-input-container {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f1f2f6;
  padding: 10px;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  gap: 10px;
}

/* The text input */
.chat-input {
  flex: 1;
  padding: 10px 15px;
  border-radius: 20px;
  border: 1px solid #ccc;
  font-size: 14px;
  outline: none;
}

/* Modern, gradient-based styles for Send and Speech (mic) buttons */
.send-button,
.speech-button {
  /* A nice gradient – can change to any palette you like */
  background: linear-gradient(135deg, #667eea, #764ba2);
  
  /* Circular shape */
  width: 48px;
  height: 48px;
  border: none;
  border-radius: 50%;
  
  /* Center the icon/text */
  display: flex;
  align-items: center;
  justify-content: center;
  
  /* Modern text/icon color */
  color: #ffffff;
  font-size: 1.2rem; /* Adjust as desired */
  cursor: pointer;
  
  /* Subtle shadow for depth */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);

  /* Smooth animations for hover/active states */
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

/* Hover: slightly larger + deeper shadow */
.send-button:hover,
.speech-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
}

/* Active/pressed: small "push-down" effect */
.send-button:active,
.speech-button:active {
  transform: translateY(0);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* Optional: a different look for the "listening" state */
.speech-button.listening {
  background: linear-gradient(135deg, #ff7e5f, #feb47b); /* Warmer gradient */
  animation: pulse 1.5s infinite;
}

/* Simple pulsing animation for the listening state */
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 126, 95, 0.7);
  }
  70% {
    box-shadow: 0 0 0 20px rgba(255, 126, 95, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 126, 95, 0);
  }
}
/* For disabled input */
.chat-input:disabled {
  background-color: #e0e0e0;
  cursor: not-allowed;
  opacity: 0.7;
}

/* For disabled buttons (send-button, speech-button) */
.send-button:disabled,
.speech-button:disabled {
  background: #ccc; /* Grey background */
  cursor: not-allowed;
  opacity: 0.6;
  box-shadow: none; /* Remove shadows if desired */
  transform: none; /* Stop hover/active transforms */
}

/* The footer inside chatbot (small bar) */
.chatbot-footer {
  height: 35px;
  background: linear-gradient(45deg, #009ffd, #2a2a72);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.85rem;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
}

/* Options (restProds) container */
.rest-prods-container {
  margin: 20px 0;
  text-align: center;
}

.select-to-view-more {
  font-size: 1rem;
  margin-bottom: 10px;
  color: #333;
  font-weight: 600;
}

.rest-prods-options {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.option-button {
  background-color: #fff;
  color: #1f8de7;
  border: 1px solid #ebe9e9;
  padding: 10px 15px;
  border-radius: 15px;
  cursor: pointer;
  transition: border-color 0.3s ease;
}

.option-button:hover {
  border-color: #c5c3c3;
}

/*
  CHAT INPUT CONTAINER (Sticky at bottom)
  with modern, round input and round buttons
*/
.chat-input-container {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #f1f2f6;
  padding: 10px;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
}

/* The text input */
.chat-input {
  flex-grow: 1;
  padding: 10px;
  border-radius: 20px;
  border: 1px solid #ccc;
  font-size: 14px;
  outline: none;
}

/* SEND and SPEECH button - right side of the chat input */
.send-button,
.speech-button {
  min-width: 45px;
  min-height: 45px;
  border: none;
  border-radius: 50%;
  background-color: #009ffd;
  color: #fff;
  font-size: 1.0rem;
  cursor: pointer;
  transition: background 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.send-button:hover,
.speech-button:hover {
  background-color: #2a2a72;
}

/*
  FOOTER 
  Minimal bar at the very bottom
*/
.footer {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(45deg, #2a2a72, #009ffd);
  color: #fff;
  font-size: 0.9rem;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
}

/* Button styles for the table */
.select-button {
  padding: 8px 16px;
  /* Padding for button size */
  border: none;
  /* Remove default border */
  background: linear-gradient(145deg, #7bb2ec, #599de7);
  /* Gradient background */
  color: white;
  /* White text */
  border-radius: 25px;
  /* Rounded button */
  font-weight: bold;
  /* Bold text for emphasis */
  cursor: pointer;
  /* Pointer cursor */
  transition: background 0.3s ease, transform 0.2s ease;
  /* Smooth transition */
  font-size: 0.9rem;
  /* Font size for the button */
  box-shadow: 0 4px 8px rgba(0, 123, 255, 0.2);
  /* Subtle shadow */
}

/* Hover effect for the select button */
.select-button:hover {
  background: linear-gradient(145deg, #0056b3, #004494);
  /* Darker gradient on hover */
  transform: translateY(-2px);
  /* Lift effect */
  box-shadow: 0 8px 16px rgba(0, 123, 255, 0.3);
  /* Enhanced shadow on hover */
}

/* Additional styles for button focus */
.select-button:focus {
  outline: none;
  /* Remove outline on focus */
  box-shadow: 0 0 0 4px rgba(0, 123, 255, 0.5);
  /* Add shadow outline */
}

/*
  MEDIA QUERIES:
  - Adjust for smaller screens
*/
@media (max-width: 600px) {
  .chat-message {
    max-width: 95%;
  }

  .product-card {
    width: 100%;
    /* On mobile, cards go full width */
    margin: 0 auto;
  }

  .chat-input {
    font-size: 13px;
  }

  .send-button,
  .speech-button {
    font-size: 0.9rem;
    min-width: 40px;
    min-height: 40px;
  }

  .footer {
    font-size: 0.8rem;
  }

  .product-table th,
  .product-table td {
    font-size: 0.85rem;
    padding: 8px;
  }
}