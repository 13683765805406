/* Main container for the app */
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Main content to allow flexible resizing */
.main-content {
  flex: 1;
  padding: 20px;
  background-color: #f4f4f4;
}

/* Footer */
.app-footer {
  padding: 10px 20px;
  background-color: #7ca1e2;
  color: black;
  text-align: center;
}

.app-footer p {
  margin: 0;
  font-size: 1rem;
}

/* Responsive design */
@media (max-width: 768px) {
  .main-content {
    padding: 10px;
  }
}
