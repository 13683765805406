/* Chat messages container */
.chat-message {
  display: flex;
  margin-bottom: 10px;
  max-width: 100%;
}

/* User message styling */
.user-message {
  background-color: #dddada;
  color: #333;
  padding: 10px;
  border-radius: 10px;
  word-break: break-word; 
  max-width: 75%; 
  min-width: 50px; 
  text-align: left;
  align-self: flex-start; 
  width: fit-content; 
}

/* Agent message styling */
.agent-message {
  background-color: #d6e5f1;
  color: #333;
  padding: 10px;
  border-radius: 10px;
  word-break: break-word; 
  max-width: 75%; 
  min-width: 50px; 
  text-align: left; 
  align-self: flex-end; 
  margin-left: auto; 
  width: fit-content; 
}

/* For mobile responsiveness */
@media (max-width: 600px) {
  .user-message, .agent-message {
    max-width: 90%; 
  }
}
