/* Chatbot Options Container */
.options-container {
  display: flex;
  flex-direction: column; /* Stack rows vertically */
  margin-top: 10px;
  overflow-x: auto; /* Allow horizontal scrolling if needed */
}

/* Each row of options */
.options-row {
  display: flex;
  justify-content: space-between; /* Space between buttons */
  width: 100%;
  margin-bottom: 10px; /* Space between rows */
}

/* Wrap options and align them */
.options {
  display: flex;
  flex-wrap: wrap; /* Allow buttons to wrap to the next line */
  justify-content: flex-start; /* Align buttons to the left */
  margin-top: 10px;
}

/* Option Button Styling */
.option-button {
  background-color: rgb(255, 255, 255);
  color: rgb(31, 141, 231);
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  border: 1px solid rgb(235, 233, 233);
  border-radius: 15px;
  margin: 5px;
  flex: 0 1 calc(33.333% - 10px); /* 3 buttons per line with spacing */
  box-sizing: border-box; /* Include padding and border in the element's total width */
  transition: border-color 0.3s ease-in-out;
}

.option-button:hover {
  border-color: rgb(197, 195, 195); /* Slight border change on hover */
}

.option-button:last-child {
  margin-right: 0; /* No margin on the last button */
}

.table-container {
  text-align: center;
  margin-bottom: 20px;
}

.table-header {
  font-size: 1.5rem;
  font-weight: bold;
  color: #007bff; /* Modern blue color matching table theme */
  margin-bottom: 10px;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
  justify-content: center; /* Center the cards horizontally */
  width: 100%;
}

/* INDIVIDUAL PRODUCT CARD */
.product-card {
  background: linear-gradient(145deg, #ffffff, #c6d4ec);
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  padding: 25px;
  width: 320px; /* Fixed-ish width for each card; adjust as needed */
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Hover effect */
.product-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
}

/* Card content heading */
.product-card h3 {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 10px;
}

/* 
  CARD OPTIONS (Select, Show More)
*/
.card-options {
  display: flex;
  justify-content: center; 
  margin-top: 15px;
  gap: 10px;
}

.card-options button {
  padding: 8px 15px;
  border: none;
  background: linear-gradient(145deg, #7bb2ec, #599de7);
  color: white;
  cursor: pointer;
  border-radius: 25px;
  font-weight: 600;
  transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease;
  box-shadow: 0 4px 10px rgba(0, 123, 255, 0.2);
  font-size: 0.9rem;
}

/* Hover effect on card option buttons */
.card-options button:hover {
  background-color: #7ca1e2;
  transform: translateY(-2px);
  box-shadow: 0 8px 20px rgba(0, 86, 179, 0.3);
}

/* 
  EXPANDED TABLE 
  Center horizontally and allow horizontal scroll if too wide
*/
/* Table expansions in the card */
.expanded-table {
  margin-top: 10px;
  display: block;
  width: 100%;                /* Container spans full width */
  overflow-x: auto;           /* Scroll horizontally if table is too wide */
  -webkit-overflow-scrolling: touch; /* Smooth iOS scroll */
  /* optional: text-align: center; 
     but margin: 0 auto on the table is usually enough to center if narrower than container
  */
}

.expanded-table table {
  margin: 0 auto;             /* Center the table horizontally if it's narrower than the container */
  width: max-content;         /* Let table expand to fit its content */
  min-width: 600px;           /* Or 800px if you have many columns */
  border-collapse: collapse;
  margin-top: 10px;           /* Or remove this if you don't need extra spacing */
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}


.expanded-table th,
.expanded-table td {
  border: 1px solid #e0e0e0;
  padding: 10px;
  text-align: center;
  font-size: 14px;
}
.expanded-table th {
  background-color: #7ca1e2;
  color: #ffffff;
  font-weight: bold;
}
.expanded-table tr:hover {
  background-color: #f7f4f4;
}

/* 
  REST PRODS (Option Buttons) 
  Center them
*/
.rest-prods-container {
  margin: 20px 0;
  text-align: center;
}
.rest-prods-options {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}
.select-to-view-more {
  font-size: 1rem;
  color: #333;
  font-weight: bold;
}

/* Quote Option Button Styling */
.quote-option-button {
  background-color: blue;
  color: white;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  margin: 5px;
  border-radius: 5px; /* Rounded corners */
  transition: background-color 0.3s ease;
}

.quote-option-button:hover {
  background-color: darkblue; /* Darker blue on hover */
}

/* Responsive Design for Option Buttons */
@media (max-width: 600px) {
  .option-button {
    flex: 1 1 100%; /* Buttons take full width on mobile */
  }
  
  .quote-option-button {
    flex: 1 1 100%; /* Quote buttons also take full width */
  }
  .product-table th, .product-table td {
    font-size: 0.85rem;
    padding: 8px;
  }
}
